<template>
  <div :class="[isMobile ? 'mobile-protect-information-third' : '', 'protect-information-third']">
    <div class="information-content">
      <div class="pdf-title">第三方信息共享清单</div>
      <div class="information_wrapper">
        <div class="title">一、第三方SDK列表</div>
        <div class="desc">为保障明亚相关平台（含官方网站、APP、微信公众号、微信小程序）的稳定运行，和实现保险服务、设备厂商消息推送等相关功能，我们需要在您使用对应功能时向第三方共享您的个人信息，如果您未使用相关功能或未授权我们共享您的信息，我们不会将您的个人信息与第三方共享。<br/>为向您完整、清晰地告知我们与第三方的信息共享情况，我们将可能与存在信息共享的主体，以及涉及个人信息类型、使用目的、处理方式等信息展示如下。</div>
        <div class="table_wrap">
          <table>
            <tr class="hd">
              <th>平台</th>
              <th>名称</th>
              <th>第三方机构名称</th>
              <th>涉及个人信息</th>
              <th>使用目的和支持的业务功能</th>
              <th>收集方式</th>
              <th>隐私政策地址</th>
            </tr>
            <tr v-for="(item, index) in sdkList" :key="index">
              <td>{{item.platform}}</td>
              <td>{{item.name}}</td>
              <td>{{item.thirdOrg}}</td>
              <td>{{item.personInfo}}</td>
              <td>{{item.purposeFunction}}</td>
              <td>{{item.collectMethod}}</td>
              <td>{{item.privacyAddress}}</td>
            </tr>
          </table>
        </div>
        <div class="title">二、第三方共享个人信息列表</div>
        <div class="desc">为了使您能够接收信息推送、在第三方平台分享信息及完成支付，我们的应用中接入了第三方合作方，具体第三方信息如下表所述：</div>
        <div class="table_wrap">
          <table>
            <tr class="hd">
              <th>产品/类型</th>
              <th>使用目的</th>
              <th>使用场景</th>
              <th>共享信息名称</th>
              <th>共享方式</th>
              <th>第三方公司</th>
              <th>隐私政策地址</th>
            </tr>
            <tr v-for="(item, index) in shareList" :key="index">
              <td>{{item.product}}</td>
              <td>{{item.purpose}}</td>
              <td>{{item.scene}}</td>
              <td>{{item.shareName}}</td>
              <td>{{item.shareType}}</td>
              <td>{{item.thirdOrg}}</td>
              <td>{{item.privacyAddress}}</td>
            </tr>
          </table>
        </div>
        <div class="desc">您在投保特定保险产品时， 我们需要与该产品承保的保险公司或为该产品提供保险服务的保险中介机构共享您的个人信息，才能安排其为您提供后续核保、承保、理赔等保险服务，具体共享情况说明如下：</div>
        <div class="title">共享的信息：</div>
        <div class="desc">1、个人信息： 投保人和被保险人的姓名、 有效身份证件的种类、号码和 有效期限、户口簿、出生日期、性别、民族、国籍、地址 （户口所在地、常住住址、房屋所在地址、快递地址）、婚姻状况、机动车辆车牌号、教育工作信息、健康生理信息、收入、人脸信息、 投保人与被保险人、 受益人之间的关系； 受益人的姓名、身份证件、 联系方式，以及保险订单信息及投保所需的必要信息。</div>
        <div class="desc">2、通信信息： 电话录音、在线沟通记录及投保轨迹记录。</div>
        <div class="desc">3、财产信息：银行卡开户行、银行卡卡号、银行预留手机号；其他第三方支付机构的账户信息 （微信支付账号信息、支付宝账号信息）。</div>
        <div class="desc">4、保全及理赔信息：购买的保险产品的名称、保险公司、保单号、保险 责任细节、报案人姓名、 电话、出险描述、身份证件、保单信息、银行账户信息、申请人关系证明文件、身故证明、伤残鉴定报告、医疗就诊信息、医疗费用凭证。</div>
        <div class="desc"><span class="bold">使用目的：</span>提供保险核保、承保、理赔等保险服务。</div>
        <div class="desc"><span class="bold">使用场景：</span>在用户投保时使用</div>
        <div class="desc"><span class="bold">共享方式：</span>接口传输</div>
        <div class="cooperation_orgs">
          <div class="desc">合作的保险公司及保险中介机构清单详见：<span class="link_cont" @click="goToOrg">https://www.mingya.com.cn/#/mechanism</span></div>
        </div>
      </div>
      <div class="page">
        <div class="btn-back" @click="goBack">返回列表</div>
      </div>
    </div>
  </div>
</template>
<script>
import { isMobile } from "@/helper/utils";
import { getThirdSdkList, getThirdShareList } from '@/api/disclosure'
export default {
  name: 'ThirdInformation',
  data() {
    return {
      isMobile: isMobile(),
      sdkList: [],
      shareList:[],
    };
  },
  created(){
    this.getSdkList()
    this.getShareList()
    // 微信公众号用户协议、委托协议签署内嵌了网页，特殊处理一些样式
    if(this.$route.query.funtionOpenPage){
      document.querySelector('#app').classList.add('funtionOpenPage')
    }
  },
  mounted() {
    this.$emit('loading', false)
  },
  methods: {
    // 获取sdk清单数据
    async getSdkList(){
      const res = await getThirdSdkList({})
      this.sdkList = res.list
    },
    // 获取共享清单数据
    async getShareList(){
      const res = await getThirdShareList({})
      this.shareList = res.list
    },
    // 跳转合作机构
    goToOrg(){
      const query = {}
      if(this.$route.query.funtionOpenPage){
        query.funtionOpenPage = this.$route.query.funtionOpenPage
      }
      this.$router.push({
        name: 'Mechanism',
        query
      })
    },
    goBack() {
      this.$router.go(-1)
    },
  },
};
</script>

<style lang="scss" scoped>
.protect-information-third {
  margin-left: 34px;
  .information-content {
    padding-top: 0;
    text-align: center;
    .page {
      display: flex;
      justify-content: flex-end;
      padding: 0.4rem 0 0;
      border-top: 1px solid #dcdcdc;
      margin-top: 0.6rem;
      .btn-back {
        width: 1.4rem;
        height: 0.44rem;
        border: 1px solid #d0d0d0;
        border-radius: 0.22rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.16rem;
        color: #666666;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
          background-color: #ee5400;
          border-color: #ee5400;
          color: #ffffff;
        }
      }
    }
    .pdf-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 0.36rem;
      color: #333333;
      font-style: normal;
      margin: 22px 0;
    }
  }
  .information_wrapper{
    .title{
      font-weight: 600;
      font-size: 0.16rem;
      color: #333333;
      line-height: 0.24rem;
      text-align: left;
      margin-bottom: 0.1rem;
    }
    .desc{
      font-weight: 400;
      font-size: 0.16rem;
      color: #333333;
      line-height: 0.24rem;
      text-align: left;
      margin-bottom: 0.12rem;
      .bold{
        font-weight: 600;
      }
    }
    .cooperation_orgs{
      background: #EDF4FF;
      border-radius: 0.1rem;
      padding: 0.16rem;
      .desc{
        margin: 0;
      }
      .link_cont{
        font-weight: 600;
        font-size: 0.16rem;
        color: #4682E2;
        line-height: 0.18rem;
        text-align: left;
        cursor: pointer;
        text-decoration: none;
      }
    }
    table {
      width: 100%;
      border-radius: 0.08rem;
      margin-bottom: 0.1rem;
      border: 1px solid #cacaca;
      border-collapse: separate;
      border-spacing: 0;
      overflow: hidden;
      tr {
        td {
          padding: 0.04rem 0.06rem;
          border-right: 1px solid #cacaca;
          border-bottom: 1px solid #cacaca;
          text-align: center;
          font-size: 0.16rem;
          word-break: break-all;
          &:last-child{
            border-right: 0;
          }
        }
        
        th {
          border-right: 1px solid #cacaca;
          border-bottom: 1px solid #cacaca;
          padding: 0.04rem 0.06rem;
          font-weight: 400;
          font-size: 0.16rem;
          &:last-child{
            border-right: 0;
          }
        }
        &:last-child{
          td{
            border-bottom: 0;
          }
        }
      }
      .hd {
        background-color: #EEF3F9;
        height: 0.4rem;
        line-height: 0.4rem;
        text-align: center;
        width: 100%;
        th {
          text-align: center;
          color: #333;
          position: relative;
          padding: 0 0.04rem;
          line-height: 1.4;
          text-align: center;
          width: 14%;
          &:nth-of-type(3) {
            width: 16%;
          }
        }
      }
    }
  }
}

</style>
<style lang="scss">
.funtionOpenPage{
  padding: 0 !important;
  .mask{
    top: 0 !important;
  }
  header,
  .header,
  .mobile-header,
  .footer-container,
  .about-side-bar,
  .m-service,
  .page,
  .back-to-top,
  .pdf-title{
    display: none !important;
  }
  .page-mian,
  .protect-information-third{
    margin-left:0 !important;
  }
  .information_wrapper{
    .title,
    .desc,
    .link_cont,
    td, th{
      font-size: 0.2rem !important;
      line-height: 0.34rem !important;
    }
  }
  .table_wrap{
    overflow: auto;
    table{
      width: 200% !important;
    }
  }
}
</style>